import classes from "./overview.module.css";
import Card from "./Card/card.component";
import User from "../../assets/icon/users-dash.svg";
import Dollar from "../../assets/icon/dollar.svg";
import Masterclass from "../../assets/icon/masterclass.svg";

const Overview = () => {
  const data = [
    {
      key: "O1",
      title: "Total Users",
      detail: "7,543",
      img: User,
      color: "#59B4E0",
    },
    {
      key: "O2",
      title: "Total Members",
      detail: "6,050",
      img: User,
      color: "#D2ECFF",
    },
    {
      key: "O3",
      title: "Total Revenue",
      detail: "$121,000",
      img: Dollar,
      color: "#84FF90",
    },
    {
      key: "O4",
      title: "Total Masterclasses",
      detail: "245",
      img: Masterclass,
      color: "#FFE5A0",
    },
  ];
  const unpack = (
    <div className={classes.unpack}>
      {data.map((item) => (
        <Card {...item} />
      ))}
    </div>
  );

  return (
    <div className={classes.overview}>
      <h3>Overview</h3>
      {unpack}
    </div>
  );
};

export default Overview;
