import React, { Fragment } from "react";
import classes from "./Speaker.module.css";
import Header from ".././../components/Header/header.component";
import { NavLink, Outlet, useOutletContext } from "react-router-dom";

const Speakers = () => {
  const [setShowNav] = useOutletContext();
  return (
    <Fragment>
      <Header page="Speakers"  setShowNav={setShowNav}/>
      <div className={classes.speakersNav}>
        <NavLink
          to="/speakers"
          end
          className={({ isActive }) =>
            isActive ? `${classes.link} ${classes.active}` : `${classes.link}`
          }
        >
          Approved Speakers
        </NavLink>

        <NavLink
          to="/speakers/applications"
          end
          className={({ isActive }) =>
            isActive ? `${classes.link} ${classes.active}` : `${classes.link}`
          }
        >
          Applications
        </NavLink>
      </div>
      <Outlet />
    </Fragment>
  );
};

export default Speakers;
