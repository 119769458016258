import React, { Fragment, useState, useRef } from "react";
import Header from ".././../components/Header/header.component";
import { TagInput2 } from "../../components/input";
import { useEditVideo } from "../../hooks/videos.hook";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Loader from "../../components/Loader";
import { SpeakerInput } from "../../components/input";
import { useGetAllSpeakers } from "../../hooks/users.hook";
import { useLocation, Link } from "react-router-dom";
import classes from "../CreateVideo/styles.module.css";

const EditVideo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state?.videoData;
  delete data?.video;
  delete data?.coverImage;
  const tags = data?.tags ? data.tags.map(tag => tag.name) : []
  const [videoData, setVideoData] = useState({...data, tags: tags, speaker: data?.speaker.id});
  const { mutate, isLoading } = useEditVideo();

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("id", videoData.id);
    formData.append("title", videoData.title);
    formData.append("speaker", videoData.speaker);
    formData.append("description", videoData.description);
    formData.append("type", videoData.type);
    formData.append("tags", videoData.tags);
    console.log('formData', formData )
    mutate(formData, {
      onSuccess: () => {
        navigate("/videos");
      },
    });
  };

  const videoDataChangeHandler = (e) => {
    const { name, value } = e.target;
    setVideoData({ ...videoData, [name]: value });
  };

  return (
    <Fragment>
      <Header page="Add New Video" back />
      <div className={classes.b}>
        <form className={classes.form} onSubmit={submitHandler}>
          <div className={classes.formGroup}>
          <label htmlFor="title">Title</label>
            <input
              type="text"
              placeholder="Title"
              name="title"
              onChange={videoDataChangeHandler}
              value={videoData.title}
              required
            />
          </div>
          <div className={classes.formGroup}>
          <label htmlFor="description">Description</label>
            <textarea
              name="description"
              placeholder="Description"
              onChange={videoDataChangeHandler}
              value={videoData.description}
              required
            ></textarea>
          </div>
          <div className={`${classes.formGroup} ${classes.w50}`}>
            <label htmlFor="type">Type</label>
            <select
              name="type"
              id="type"
              onChange={videoDataChangeHandler}
              value={videoData.type}
              required
            >
              <option value="free">Free</option>
              <option value="members">Insiders Only</option>
            </select>
          </div>
          <TagInput2 setData={setVideoData} data={videoData} w />
          <div className={classes.submit}>
            {isLoading ? <Loader /> : <input type="submit" value="SAVE" />}
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default EditVideo;
