import React, { useState } from "react";
import Modal from "../../components/UI/Modal/Modal";
import SuccessImage from "../../assets/icon/success.svg";
import classes from "../Events/Events.module.css";
import { VideoCard } from "../../components/UI/Card";
import { useSetFeaturedVideo } from "../../hooks/videos.hook";

const SetFeatured = (props) => {
  const [success, setSuccess] = useState(false);
  const [selectedVideoId, setSelectedVideoId] = useState(null);

  const { mutate, isLoading } = useSetFeaturedVideo();

  const selectHandler = (videoId) => {
    setSelectedVideoId(videoId);
  };
  const setFeaturedHanler = () => {
    mutate(
      {data:{ video_id: selectedVideoId }},
      {
        onSuccess: () => {
          setSuccess(true);
          props.refetch()
        },
      }
    );
  };
  return (
    <Modal onClose={props.onClose}>
      {success ? (
        <div className={classes.successMessage}>
          <img src={SuccessImage} alt="Successful" />
          <h3 className="text-center">Featured Video Set Successfully!</h3>
        </div>
      ) : (
        <>
          <div className="max-w-[600px]">
            <h1 className="text-center font-bold text-lg mb-7">
              Featured Video
            </h1>
            <p className="mb-7">Search for or select from the videos below</p>
            <div>
              <input
                type="text"
                className="bg-transparent border-2 rounded-3xl w-full px-5 py-4"
                placeholder="Search"
              />
            </div>
            <div className="mt-12">
              <h1 className="font-semibold mb-4">Latest Videos</h1>
              <div className="flex flex-wrap gap-4">
                {props.data.map((item) => (
                  <VideoCard
                    key={item.id}
                    {...item}
                    action={() => selectHandler(item.id)}
                  />
                ))}
              </div>
            </div>
          </div>
          {selectedVideoId && (
            <button
              className="sticky bottom-0 left-[50%] translate-x-[-50%] bg-sky-blue py-4 px-10 w-[50%] rounded-full text-black"
              onClick={setFeaturedHanler}
            >
              SELECT
            </button>
          )}
        </>
      )}
    </Modal>
  );
};

export default SetFeatured;
