import React, { Fragment, useState } from "react";
import Header from ".././../components/Header/header.component";
import { useLocation, Link, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import Dialog from "../../components/UI/Dialog";
import { useDeleteVideo } from "../../hooks/videos.hook";

const Details = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [deleteModal, setDeleteModal] = useState(false);
  const videoData = location.state?.videoData;
  const { mutate, isLoading } = useDeleteVideo();
  const deleteV = () => {
    mutate({ id: videoData.id },
      {
        onSuccess: () => {
          navigate("/videos");
        },
      }
    );
  };

  return (
    <Fragment>
      <Header page="Details" back />
      {deleteModal && (
        <Dialog
          onClose={() => setDeleteModal(false)}
          actionMsg={`Are you sure you want to delete "${videoData?.title}"`}
          action={deleteV}
          status={isLoading}
        />
      )}
      <div className="mx-3.5 md:mr-2.5 md:ml-0 overflow-auto">
        <div className="relative w-full max-w-[800px] h-[249px]  md:h-[428px] mx-auto pb-4">
          <ReactPlayer
            url={videoData?.video}
            controls={true}
            light={videoData?.coverImage}
            width="100%"
            height="100%"
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload",
                },
              },
            }}
          />
        </div>

        <div className="mb-2.5 md:mb-6">
          <h1 className="text-2xl font-bold">{videoData?.title}</h1>
          <h3 className="font-semibold mt-2 md:mt-4">
            {videoData?.speaker.first_name} {videoData?.speaker.last_name}
          </h3>
        </div>
        <div className="mb-2.5 md:mb-6">
          <h3 className="text-sky-blue font-semibold">Description</h3>
          <p className="text-base md:text-lg leading-8">
            {videoData?.description}
          </p>
        </div>
        <div className="mb-2.5 md:mb-6">
          <h3 className="text-sky-blue font-semibold">Tags</h3>
          <div className="flex gap-2.5 mt-1.5 flex-wrap">
            {videoData?.tags.map((item, index) => (
              <div
                key={index}
                className="px-5 py-2.5 bg-white text-black rounded-full capitalize font-semibold text-sm"
              >
                {item.name}
              </div>
            ))}
          </div>
        </div>
        <div className="mb-2.5 md:mb-6">
          <h3 className="text-sky-blue font-semibold">Type</h3>

          <p className="px-5 py-2.5 bg-white mt-1.5 text-black rounded-full capitalize font-semibold text-sm w-fit">
            {videoData?.type}
          </p>
        </div>
        <div className="flex gap-5 font-bold text-sm my-10">
          <Link
            key={videoData.id}
            to={`/videos/edit/${videoData.id}`}
            state={{ videoData: videoData }}
          >
            <button className="px-10 py-4 text-black bg-sky-blue uppercase rounded-full">
              Edit
            </button>{" "}
          </Link>
          <button
            className="text-sky-blue uppercase"
            onClick={() => setDeleteModal(true)}
          >
            Delete
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default Details;
