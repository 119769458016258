import { useMutation, useQuery } from "react-query";
import { Api } from "../api/Api";
import { toast } from "react-hot-toast";
import { handleError } from "../utils/utils";

export const useGetEvents = (currentPage) => {
  return useQuery(
    ["get-events"],
    async () => await Api.events.getAllEvents(),
    {
      onError: (err) => {
        handleError(err);
      },
    }
  );
};

export const useGetFeaturedEvent = () => {
  return useQuery(
    ["get-featured-events"],
    async () => await Api.events.useGetFeaturedEvent(),
    {
      onError: (err) => {
        handleError(err);
      },
    }
  );
};

export const useSetFeaturedEvent = () => {
  return useMutation((data) => Api.events.setFeaturedEvent(data), {
    onError: (err) => {
      toast.error(err)
      handleError(err);
    },
  });
};

export const useCreateEvent = () => {
  return useMutation((data) => Api.events.createEvent(data), {
    onError: (err) => {
      toast.error(err)
      handleError(err);
    },
  });
};

export const useEditEvent = () => {
  return useMutation((data) => Api.events.editEvent(data), {
    onError: (err) => {
      toast.error(err);
    },
  });
};

export const useDeleteEvent = () => {
  return useMutation((data) => Api.events.deleteEvent(data), {
    onError: (err) => {
      toast.error(err);
    },
  });
};
