import React from "react";
import classes from "./pagination.module.css";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageClick = (pageNumber) => {
    onPageChange(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const showPageNumbers = 2;
    let startPage = currentPage - showPageNumbers;
    let endPage = currentPage + showPageNumbers;

    if (startPage <= 0) {
      startPage = 1;
    }

    if (endPage > totalPages) {
      endPage = totalPages;
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={currentPage === i ? classes.active : ""}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <ul className={classes.pagination}>
      {currentPage > 1 && (
        <li onClick={() => handlePageClick(currentPage - 1)}>Previous</li>
      )}
      {renderPageNumbers()}
      {currentPage < totalPages && (
        <li onClick={() => handlePageClick(currentPage + 1)}>Next</li>
      )}
    </ul>
  );
};

export default Pagination;
