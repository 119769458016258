import React, { useState, Fragment } from "react";
import classes from "./Users.module.css";
import Header from ".././../components/Header/header.component";
import Table from "../../components/Table/table";
import { useGetUsers } from "../../hooks/users.hook";
import Loader from "../../components/Loader";
import Pagination from "../../components/UI/Pagination/pagination";
import { useOutletContext } from "react-router-dom";

const UsersPage = () => {
  const [setShowNav] = useOutletContext();
  const [currentPage, setCurrentPage] = useState(1);

  // const { data, isFetching, refetch } = useGetUsers(currentPage);
  const { data, isFetching } = useGetUsers(currentPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    // refetch();
  };

  return (
    <Fragment>
      <Header page="Users" setShowNav={setShowNav}/>

      <div className={classes.users}>
        {isFetching ? (
          <Loader />
        ) : (
          <>
            <div className={classes.header}>
              <h3>All Users</h3>
              <div className={classes.pagination}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={data?.data?.pages}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
            <div className={classes.body}>
              <Table usersData={data?.data?.data} />
            </div>
          </>
        )}
      </div>
    </Fragment>
  );
};

export default UsersPage;
