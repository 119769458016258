import React, { useState } from "react";
import classes from "./EventCard.module.css";
import EventImage from "../../../assets/img/eventcardImg.png";
import Calender from "../../../assets/icon/calender.svg";
import Time from "../../../assets/icon/time.svg";
import moment from "moment";
import { timeToAMPM } from "../../../utils/utils";
import { useDeleteEvent } from "../../../hooks/events.hook";
import Dialog from "../Dialog";
import { useNavigate } from "react-router-dom";

const EventCard = ({ handler, data, refetch, action }) => {
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState(false);
  const { mutate, isLoading } = useDeleteEvent();
  const deleteE = () => {
    mutate(
      { id: data.id },
      {
        onSuccess: () => {
          refetch();
        },
      }
    );
  };
  return (
    <>
      {deleteModal && (
        <Dialog
          onClose={() => setDeleteModal(false)}
          actionMsg={`Are you sure you want to delete "${data?.name}"`}
          action={deleteE}
          status={isLoading}
        />
      )}
      <div className={classes.card} onClick={action}>
        <div className={classes.imageContainer}>
          <div className={classes.imageWrapper}>
            <img src={data.cover || EventImage} alt="Event Image" />
          </div>
        </div>
        <h4 className="capitalize">{data.type || "Free"}</h4>
        <div className={classes.det}>
          <div className={classes.left}>
            <h2 className="truncate w-[192px]">
              {data.name || "Urge Talk Conference"}
            </h2>
            <div>
              <div>
                <img src={Calender} alt="Date" />
                <h5>
                  {moment(data.date).format("Do MMMM, YYYY") || "27 May, 2023"}
                </h5>
              </div>
              <div>
                <img src={Time} alt="Time" />
                <h5>{timeToAMPM(data.time) || "10:00 AM"}</h5>
              </div>
            </div>
          </div>
          <div className={classes.right}>
            {handler && (
              <>
                <button className={classes.edit} onClick={() => handler(data)}>EDIT</button>
                <button className={classes.delete} onClick={() => setDeleteModal(true)}>Delete</button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EventCard;
