import { useMutation, useQuery } from "react-query";
import { Api } from "../api/Api";
import { toast } from "react-hot-toast";
import { handleError } from "../utils/utils";

export const useGetVideos = (currentPage) => {
  return useQuery(
    ["get-videos", currentPage],
    async () => await Api.videos.getAllVideos(currentPage),
    {
      onError: (err) => {
        handleError(err);
      },
    }
  );
};
export const useGetFeaturedVideo = () => {
  return useQuery(
    ["get-featured-videos"],
    async () => await Api.videos.useGetFeaturedVideo(),
    {
      onError: (err) => {
        handleError(err);
      },
    }
  );
};

export const useSetFeaturedVideo = () => {
  return useMutation((data) => Api.videos.setFeaturedVideo(data), {
    onError: (err) => {
      toast.error(err)
      handleError(err);
    },
  });
};

export const useCreateVideo = () => {
  return useMutation((data) => Api.videos.uploadVideo(data), {
    onError: (err) => {
      toast.error(err)
      handleError(err);
    },
  });
};

export const useEditVideo = () => {
  return useMutation((data) => Api.videos.editVideo(data), {
    onError: (err) => {
      toast.error(err);
    },
  });
};

export const useDeleteVideo = () => {
  return useMutation((data) => Api.videos.deleteVideo(data), {
    onError: (err) => {
      toast.error(err);
    },
  });
};
