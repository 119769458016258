import { useQuery } from "react-query";
import { Api } from "../api/Api";
import { toast } from "react-hot-toast";
import { handleError } from "../utils/utils";

export const useGetUsers = (currentPage) => {
  return useQuery(
    ["get-users", currentPage],
    async () => await Api.users.getUsers(currentPage),
    {
      onError: (err) => {
        handleError(err);
      },
    }
  );
};

export const useGetSpeakers = (currentPage) => {
  return useQuery(
    ["get-speakers", currentPage],
    async () => await Api.users.getSpeakers(currentPage),
    {
      onError: (err) => {
        handleError(err);
      },
    }
  );
};
export const useGetPending = (currentPage) => {
  return useQuery(
    ["get-pending", currentPage],
    async () => await Api.users.getPending(currentPage),
    {
      onError: (err) => {
        handleError(err);
      },
    }
  );
};
export const useGetRejected = (currentPage) => {
  return useQuery(
    ["get-pending", currentPage],
    async () => await Api.users.getRejected(currentPage),
    {
      onError: (err) => {
        handleError(err);
      },
    }
  );
};

export const useGetAllSpeakers = () => {
  return useQuery(
    ["all-speaker"],
    async () => await Api.users.getAllSpeakers(),
    {
      onError: (err) => {
        handleError(err);
      },
    }
  );
};
