import axios from "axios";
import { USER_API_URL } from "../utils/constants";
import { Auth } from "../utils/storage";

export const UserAxiosInstance = axios.create({
  baseURL: USER_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

UserAxiosInstance.interceptors.response.use(
  function (response) {
    return Promise.resolve(response);
  },
  function (error) {
    if (error.response?.data?.message) {
      return Promise.reject(error.response?.data?.message);
    }
    return Promise.reject(error?.message);
  }
);

export const AuthUserAxiosInstance = axios.create({
  baseURL: USER_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

AuthUserAxiosInstance.interceptors.request.use(
  async (config) => {
    config.headers = {
      Accept: "application/json",
    };
    config.headers.authorization = `Bearer ${Auth.getToken()}`;
    config.timeout = 0;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

AuthUserAxiosInstance.interceptors.response.use(
  function (response) {
    return Promise.resolve(response);
  },
  function (error) {
    if (error.response?.data?.message) {
      return Promise.reject(error.response?.data?.message);
    }
    return Promise.reject(error?.message);
  }
);
