import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import Dashboard from "./pages/Dashboard";
import PrivateRoute from "./utils/PrivateRoute";
import UsersPage from "./pages/Users/Users";
import Speakers from "./pages/Speakers/Speakers";
import SpeakerApplications from "./pages/Speakers/SpeakerApplications";
import ApprovedSpeakers from "./pages/Speakers/ApprovedSpeakers";
import PageLayout from "./components/PageLayout/page-layout.component";
import Videos from "./pages/Videos/Videos";
import Events from "./pages/Events/Events";
import Subscription from "./pages/Subscription/Subscription";
import EventMain from "./pages/Events/Main";
import Applications from "./pages/Speakers/Applications";
import Rejected from "./pages/Speakers/Rejected";
import CreateVideo from "./pages/CreateVideo";
import Details from "./pages/VideoDetail";
import EditVideo from "./pages/EditVideo";
import Playlists from "./pages/Playlists";
import CreatePlaylist from "./pages/CreatePlaylist";
import AddToPlaylist from "./pages/AddToPlaylist";
import Competition from "./pages/Competition";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<PrivateRoute />}>
            <Route exact path="/" element={<PageLayout />}>
              <Route exact path="" element={<Dashboard />} />

              <Route exact path="users" element={<UsersPage />} />

              <Route path="speakers" element={<Speakers />}>
                <Route exact index element={<ApprovedSpeakers />} />
                <Route exact path="applications" element={<Applications />}>
                  <Route exact index element={<SpeakerApplications />} />
                  <Route exact path="rejected" element={<Rejected />} />
                </Route>
              </Route>

              <Route path="videos">
                <Route exact index element={<Videos />}/>
                <Route path="create" element={<CreateVideo />}/>
                <Route path="detail/:id" element={<Details />}/>
                <Route path="edit/:id" element={<EditVideo />}/>
              </Route>

              <Route path="playlists">
                <Route exact index element={<Playlists />}/>
                <Route path="create" element={<CreatePlaylist />}/>
                <Route path="add/:id" element={<AddToPlaylist />}/>
              </Route>
              
              
              <Route path="events" element={<Events />}>
                <Route exact index element={<EventMain />} />
              </Route>
              <Route path="subscription" element={<Subscription />}>
                {/* index and others */}
              </Route>
              <Route exact path="competition" element={<Competition />} />

              {/* next here */}
            </Route>
          </Route>

          <Route Component={LoginPage} path="/login" />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
