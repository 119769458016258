import classes from "./middlesect.module.css";
import Upload from "../../assets/icon/upload.svg";
import { useState, useRef } from "react";
import FeaturedEvent from "./FeaturedEvent/featured-event.component";

const MiddleSect = () => {
  const [file, setFile] = useState(null);
  const inputRef = useRef();

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleDrop = (event) => {
    event.preventDefault();
    setFile(event.dataTransfer.files);
  };

  return (
    <div className={classes.cont}>
      <div className={classes.left}>
        <h3>Video for the day</h3>
        {!file ? (
          <div
            className={classes.dropzone}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <img src={Upload} alt="upload" />
            <p>Drag files here to upload</p>
            <p>OR</p>
            <input
              type="file"
              name=""
              id=""
              onChange={(event) => setFile(event.target.files)}
              hidden
              ref={inputRef}
            />

            <button onClick={() => inputRef.current.click()}>Browse</button>
          </div>
        ) : (
          <div>
            <ul>
              {Array.from(file).map((file, idx) => (
                <li key={idx}>{file.name}</li>
              ))}
            </ul>
            <button onClick={() => setFile(null)}>Cancel</button>
          </div>
        )}
      </div>
      <div className={classes.right}>
        <FeaturedEvent />
      </div>
    </div>
  );
};

export default MiddleSect;
