import React, { Fragment, useState, useEffect, forwardRef } from "react";
import classes from "./styles.module.css";
import Minus from "../../assets/icon/minus";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

export const TagInput = ({ setData, data, w }) => {
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter" || event.key === ",") {
      event.preventDefault();
      const newTag = inputValue.trim();
      if (newTag && !tags.includes(newTag)) {
        setTags([...tags, newTag]);
      }
      setInputValue("");
    }
  };
  useEffect(() => {
    setData({ ...data, tags: tags });
  }, [tags]);

  const removeTag = (tag) => {
    setTags(tags.filter((t) => t !== tag));
  };

  return (
    <div>
      <div className={`${classes.formGroup} ${w && classes.w50}`}>
        <label htmlFor="tags">Tags</label>
        <input
          type="text"
          id="tags"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
        />
      </div>
      <div className={classes.tagsContainer}>
        {tags.map((tag) => (
          <div key={tag} className={classes.tag}>
            <h3>{tag}</h3>

            <div onClick={() => removeTag(tag)}>
              <Minus />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const TagInput2 = ({ setData, data, w }) => {
  const [tags, setTags] = useState(data.tags);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter" || event.key === ",") {
      event.preventDefault();
      const newTag = inputValue.trim();
      if (newTag && !tags.includes(newTag)) {
        setTags([...tags, newTag]);
      }
      setInputValue("");
    }
  };
  useEffect(() => {
    setData({ ...data, tags: tags });
  }, [tags]);

  const removeTag = (tag) => {
    setTags(tags.filter((t) => t !== tag));
  };

  return (
    <div>
      <div className={`${classes.formGroup} ${w && classes.w50} mt-2`}>
        <label htmlFor="tags">Tags</label>
        <input
          type="text"
          id="tags"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
        />
      </div>
      <div className={classes.tagsContainer}>
        {tags?.map((tag) => (
          <div key={tag} className={classes.tag}>
            <h3>{tag}</h3>

            <div onClick={() => removeTag(tag)}>
              <Minus />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const SpeakerInput = ({ data, speakerDataChangeHandler }) => {
  const [selected, setSelected] = useState(data ? data[0] : "");
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (selected !== "") {
      speakerDataChangeHandler(selected.id);
    }
  }, [selected]);

  const filteredPeople =
    query === ""
      ? data
      : data.filter(
          (person) =>
            `${person.first_name} ${person.last_name}`
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(query.toLowerCase().replace(/\s+/g, "")) ||
            person.email
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  return (
    <div className="mb-6">
      <Combobox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <div className="relative border border-white w-full cursor-default overflow-hidden rounded-[28px] bg-transparent text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-sky-blue sm:text-sm py-2">
            <Combobox.Input
              className="w-full bg-transparent border-none py-2 pl-3 pr-10 text-sm leading-5 text-white-900 focus:ring-0 outline-none"
              displayValue={(person) =>
                `${person.first_name} ${person.last_name}`
              }
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-white-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-off-black py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredPeople?.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none py-2 px-4 text-white-700">
                  Nothing found.
                </div>
              ) : (
                filteredPeople?.map((person) => (
                  <Combobox.Option
                    key={person.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-sky-blue text-white" : "text-white-900"
                      }`
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {person.first_name} {person.last_name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-white" : "text-sky-blue"
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};

export const InputField = ({ ...props }) => {
  return (
    <div className="mb-6 flex flex-col gap-2.5">
      <input
        className="border-1 border-solid border-white rounded-3.5xl px-5 py-4 text-text bg-transparent outline-none"
        {...props}
      />
    </div>
  );
};

export const TextArea = ({ ...props }) => {
  return (
    <div className="mb-6 flex flex-col gap-2.5">
      <textarea
        className="h-40 md:h-[185px] border-1 border-solid border-white rounded-3.5xl px-5 py-4 text-text bg-transparent outline-none"
        {...props}
      ></textarea>
    </div>
  );
};

export const ImageSelector = forwardRef(
  ({ name, label, fileName, ...props }, ref) => {
    return (
      <div className="mb-6 flex justify-between border-1 border-solid border-white rounded-3.5xl px-5 py-4 items-center text-text bg-transparent">
        <label htmlFor={name}>
          {label}
        </label>
        <input
          className="hidden"
          type="file"
          accept="image/*"
          name={name}
          ref={ref}
          {...props}
        />
        {fileName ? (
          <span className="font-semibold" onClick={() => ref.current.click()}>{fileName}</span>
        ) : (
          <span className="px-10 py-3 bg-sky-blue rounded-full text-black font-semibold cursor-pointer text-sm" onClick={() => ref.current.click()}>
            Browse
          </span>
        )}
      </div>
    );
  }
);

