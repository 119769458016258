import { useMutation, useQuery } from "react-query";
import { Api } from "../api/Api";
import { toast } from "react-hot-toast";
import { handleError } from "../utils/utils";

export const useGetPlaylist = () => {
  return useQuery(
    ["get-playlist"],
    async () => await Api.playlist.getAllPlaylists(),
    {
      onError: (err) => {
        handleError(err);
      },
    }
  );
};

export const useGetEpisodes = (id) => {
  return useQuery(
    ["get-episodes", id],
    async () => await Api.playlist.getEpisodes(id),
    {
      onError: (err) => {
        handleError(err);
      },
    }
  );
};

export const useCreatePlaylist = () => {
  return useMutation((data) => Api.playlist.createPlaylist(data), {
    onSuccess: ({ data }) => {
      toast.success(data.message);
    },
    onError: (err) => {
      toast.error(err);
      handleError(err);
    },
  });
};

export const useAddEpisode = () => {
  return useMutation((data) => Api.playlist.addEpisode(data), {
    onSuccess: ({ data }) => {
      toast.success(data.message);
    },
    onError: (err) => {
      toast.error(err);
      handleError(err);
    },
  });
};

export const useDeleteEpisode = () => {
  return useMutation((id) => Api.playlist.deleteEpisode(id), {
    onSuccess: ({ data }) => {
      toast.success(data.message);
    },
    onError: (err) => {
      toast.error(err);
    },
  });
};
