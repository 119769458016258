import React, { useState } from "react";
import Table from "../../components/Table/table";
import classes from "./Speaker.module.css";
import Pagination from "../../components/UI/Pagination/pagination";
import { useGetSpeakers } from "../../hooks/users.hook";
import Loader from "../../components/Loader";

const ApprovedSpeakers = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isFetching } = useGetSpeakers(currentPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className={classes.speakers}>
      {isFetching ? (
        <Loader />
      ) : (
        <>
          <div className={classes.header}>
            <h3>Approved Speakers</h3>
            <div className={classes.pagination}>
              <Pagination
                currentPage={currentPage}
                totalPages={data?.data?.pages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
          <div className={classes.body}>
            <Table usersData={data?.data?.data} />
          </div>
        </>
      )}
    </div>
  );
};

export default ApprovedSpeakers;
