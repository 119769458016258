import React, { useState } from "react";
import { useLogin } from "../hooks/auth.hook";
import { useNavigate } from "react-router-dom";
import classes from "./LoginPage.module.css";
import LoginIcon from "../assets/icon/login.svg";
import Eye from "../assets/icon/eye.svg";
import ClosedEye from "../assets/icon/eye-close.svg";
import Mail from "../assets/icon/mail.svg";
import { useRecoilState } from "recoil";
import errorAtom from "../atoms/error.atom";
import { toast } from "react-hot-toast";

const LoginPage = () => {
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [eyes, setEyes] = useState(false);

  const [errorMessage, setErrorMessage] = useRecoilState(errorAtom);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const { mutate, isLoading } = useLogin();

  const handleSubmit = (e) => {
    e.preventDefault();
    mutate(loginData, {
      onSuccess: () => {
        navigate("/");
      },
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.flexCol}>
        <div className={classes.head}>
          <img src={LoginIcon} alt="Login" />
          <div className={classes.hor}></div>
          <h2>Login</h2>
        </div>
        <p>Fill in your details below to Login</p>
      </div>

      <div className={classes.flexCol}>
        <form onSubmit={handleSubmit}>
          <div className={classes.InputGroup}>
            <label htmlFor="email">Email Address</label>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              placeholder="example@example.com"
              required
              onChange={handleChange}
              value={loginData.email}
            />
            <div className={classes.img}>
              <img src={Mail} alt="mail" />
            </div>
            
          </div>
          <div className={classes.InputGroup}>
            <label htmlFor="password">Password</label>
            <input
              type={eyes ? "text" : "password"}
              name="password"
              placeholder="Password"
              id="password"
              onChange={handleChange}
              value={loginData.password}
            />
            <div className={classes.img}>
              {eyes ? (
                <img src={Eye} alt="mail" onClick={() => setEyes(false)}/>
              ) : (
                <img src={ClosedEye} alt="mail" onClick={() => setEyes(true)}/>
              )}
            </div>
          </div>
          <div className={classes.rem}>
            <input type="checkbox" name="remember" id="remember" />
            <label htmlFor="remember">Remember me</label>
          </div>
          <input className={`${classes.login} bg-sky-blue`} type="submit" value={isLoading ? "LOADING..." : "LOGIN"} />
        </form>
        <div className={classes.forgot}>
          <a href="">Forgot Password?</a>
        </div>
      </div>

      <div className={classes.tail}>
        <h2>Urge</h2>
        <p>All Rights Reserved</p>
      </div>
    </div>
  );
};

export default LoginPage;
