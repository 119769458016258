import React, { Fragment, useState } from "react";
import { useNavigate, Link, useOutletContext, Outlet } from "react-router-dom";
import Header from "../../components/Header/header.component";
import ButtonHeader from "../../components/UI/buttonHeader/buttonheader";
import { useGetPlaylist } from "../../hooks/playlist.hook";
import Loader from "../../components/Loader";

const Playlists = () => {
  const navigate = useNavigate();
  const [setShowNav] = useOutletContext();
  const showCreateHandler = () => {
    navigate("create");
  };
  const { data, isFetching } = useGetPlaylist();
  return (
    <Fragment>
      <Header page="Playlists" setShowNav={setShowNav} />
      <div className="mx-3.5 lg:mr-2.5 lg:ml-0 mt-5 overflow-auto">
        <ButtonHeader
          header="All Playlists"
          btn="CREATE PLAYLIST"
          handler={showCreateHandler}
        />
        {isFetching ? (
          <Loader />
        ) : (
          <div className="flex flex-wrap gap-2">
            {data?.data?.data.map((item) => (
              <div key={item.id} className="w-[280px]" onClick={() => navigate("add/"+item.id)}>
                <div className="h-[165px]">
                  <img src={item.cover} alt={item.title} className="w-full h-full rounded-lg object-cover" />
                </div>
                <div className="mt-2">
                  <h2 className="font-semibold text-base line-clamp-2">{item.title}</h2>
                  <h3 className="text-xs mt-1.5">{item.speaker}</h3>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Playlists;
