import { useQuery } from "react-query";
import { Api } from "../api/Api";
import { handleError } from "../utils/utils";

export const useGetCompetition = () => {
    return useQuery(
      ["get-competition"],
      async () => await Api.competition.getSubmissions(),
      {
        onError: (err) => {
          handleError(err);
        },
      }
    );
  };