export const Auth = {
  getToken: () => {
    const userJSON = localStorage.getItem("recoil-persist");
    let token = null;

    try {
      const user = JSON.parse(userJSON);
      token = user["user-login"]?.access;
    } catch (error) {
      console.error("Error parsing JSON data:", error);
    }

    return token;
  },
  getRefresh: () => {
    const userJSON = localStorage.getItem("recoil-persist");
    let token = null;

    try {
      const user = JSON.parse(userJSON);
      token = user["user-login"]?.refresh;
    } catch (error) {
      console.error("Error parsing JSON data:", error);
    }

    return token;
  },
};
