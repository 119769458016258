import classes from "./Events.module.css";
import Modal from "../../components/UI/Modal/Modal";
import { useState, useRef } from "react";
import SuccessImage from "../../assets/icon/success.svg";
import { useCreateEvent } from "../../hooks/events.hook";
import { TagInput } from "../../components/input";
import { useNavigate } from "react-router-dom";

const CreateEvent = (props) => {
  const navigate = useNavigate();
  const [eventData, setEventData] = useState({
    name: "",
    date: "",
    time: "",
    location: "",
    type: "free",
    amount: 0,
    description: "",
    tags: null,
  });

  const coverImageRef = useRef(null);
  const [selectedCoverImageName, setSelectedCoverImageName] = useState("");
  const [success, setSuccess] = useState(false);
  const { mutate, isLoading } = useCreateEvent();

  const handleCoverImageInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedCoverImageName(file.name);
    }
  };
  const eventDataChangeHandler = (e) => {
    const { name, value } = e.target;
    setEventData({ ...eventData, [name]: value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("cover", coverImageRef.current.files[0]);
    formData.append("name", eventData.name);
    formData.append("date", eventData.date);
    formData.append("time", eventData.time);
    formData.append("location", eventData.location);
    formData.append("type", eventData.type);
    if (eventData.type === "paid") {
      formData.append("amount", eventData.amount);
    }
    formData.append("description", eventData.description);
    formData.append("tags", eventData.tags);
    mutate(formData, {
      onSuccess: () => {
        setSuccess(true);
      },
    });
  };

  return (
    <Modal onClose={props.onClose}>
      {!success && (
        <div className={classes.cont}>
          <h1>Create New Event</h1>
          <form onSubmit={submitHandler}>
            <div className={classes.formGroup}>
              <input
                type="text"
                placeholder="Event Name"
                className={classes.type1}
                name="name"
                onChange={eventDataChangeHandler}
                value={eventData.name}
                required
              />
            </div>
            <div className={classes.formGroup}>
              <div className={classes.type2}>
                <input
                  type="date"
                  placeholder="Date"
                  name="date"
                  onChange={eventDataChangeHandler}
                  value={eventData.date}
                  required
                />
                <input
                  type="time"
                  placeholder="Time"
                  name="time"
                  onChange={eventDataChangeHandler}
                  value={eventData.time}
                  required
                />
              </div>
            </div>
            <div className={classes.formGroup}>
              <input
                type="text"
                placeholder="Location"
                name="location"
                className={classes.type1}
                onChange={eventDataChangeHandler}
                value={eventData.location}
                required
              />
            </div>
            <div className={classes.formGroup}>
              <select
                name="type"
                id="type"
                className={classes.type1}
                onChange={eventDataChangeHandler}
                value={eventData.type}
              >
                <option value="free">Free</option>
                <option value="paid">Paid</option>
                <option value="members">Members Only</option>
              </select>
            </div>
            {eventData.type === "paid" && (
              <div className={classes.formGroup}>
                <input
                  type="number"
                  placeholder="Amount"
                  name="amount"
                  min={0}
                  className={classes.type1}
                  onChange={eventDataChangeHandler}
                  value={eventData.amount}
                />
              </div>
            )}
            <div className={`${classes.formGroup} px-5 py-4`}>
              <label
                htmlFor="coverImage"
                onClick={() => coverImageRef.current.click()}
                className="cursor-pointer text-sky-blue font-semibold"
              >
                Upload Cover Image
              </label>
              <input
                name="coverImage"
                type="file"
                accept="image/*"
                ref={coverImageRef}
                onChange={handleCoverImageInputChange}
                required
                className={`${classes.type1} hidden`}
              />
              {selectedCoverImageName ? (
                <span className="ml-3">{selectedCoverImageName}</span>
              ) : (
                <span className="ml-3">No cover image selected</span>
              )}
            </div>
            <div className={classes.formGroup}>
              <textarea
                name="description"
                placeholder="Description"
                onChange={eventDataChangeHandler}
                value={eventData.description}
                required
              ></textarea>
            </div>
            <div className={classes.tags}>
              <TagInput setData={setEventData} data={eventData} />
            </div>
            <div className={classes.submit}>
              <input
                disabled={isLoading}
                type="submit"
                value={isLoading ? "CREATING EVENT ..." : "CREATE EVENT"}
              />
            </div>
          </form>
        </div>
      )}
      {success && (
        <div className={classes.successMessage}>
          <img src={SuccessImage} alt="Successful" />
          <h3>Event Created Successfully!</h3>
        </div>
      )}
    </Modal>
  );
};

export default CreateEvent;
