import React, { Fragment, useState, useRef } from "react";
import Header from "../../components/Header/header.component";
import Modal from "../../components/UI/Modal/Modal";
import { InputField, TextArea } from "../../components/input";
import classes from "../CreateVideo/styles.module.css";
import {
  useAddEpisode,
  useGetEpisodes,
  useDeleteEpisode,
} from "../../hooks/playlist.hook";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader";

const AddToPlaylist = () => {
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({});
  const [selectedVideoName, setSelectedVideoName] = useState("");
  const imageRef = useRef(null);
  const [selectedImageName, setSelectedImageName] = useState("");
  const { mutate, isLoading } = useAddEpisode();
  const { mutate: deleteEpisode, isDeleting } = useDeleteEpisode();
  const { data: episodes, isFetching, refetch } = useGetEpisodes(id);
  const videoRef = useRef(null);
  const handleVideoInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedVideoName(file.name);
    }
  };
  const dataChangeHandler = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const onClose = () => {
    setShowModal(false);
    setSelectedVideoName("");
  };
  const handleImageInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImageName(file.name);
    }
  };
  const removeEpisode = (id) => {
    deleteEpisode(id, {
      onSuccess: () => {
        refetch();
      },
    });
  };
  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("video", videoRef.current.files[0]);
    formData.append("cover", imageRef.current.files[0]);
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("playlist", id);
    mutate(formData, {
      onSuccess: () => {
        setShowModal(false);
        refetch();
      },
    });
  };
  return (
    <Fragment>
      <Header page="Add Item to Playlist" back />
      <div className="mx-3.5 lg:mr-2.5 lg:ml-0 mt-5">
        <div className="mb-4">
          <h1 className="text-sky-blue text-lg font-bold mb-0.5">
            Add Episodes
          </h1>
          <p className="font-semibold">
            Click on “Add Episodes” below to start adding to your playlist
          </p>
          {showModal && (
            <Modal onClose={onClose}>
              <h1 className="mb-5 text-center text-lg font-bold">
                Add Episode
              </h1>
              <form className="w-[500px]" onSubmit={submitHandler}>
                <InputField
                  placeholder="Title"
                  name="title"
                  onChange={dataChangeHandler}
                />
                <div className={classes.fileUp}>
                  <label
                    htmlFor="image"
                    onClick={() => imageRef.current.click()}
                  >
                    Upload Cover Image
                  </label>
                  <input
                    type="file"
                    name="image"
                    accept="image/*"
                    ref={imageRef}
                    onChange={handleImageInputChange}
                    required
                  />
                  {selectedImageName ? (
                    <span>{selectedImageName}</span>
                  ) : (
                    <span>No image selected</span>
                  )}
                </div>
                <div className={classes.fileUp}>
                  <label
                    htmlFor="video"
                    onClick={() => videoRef.current.click()}
                  >
                    Upload Video
                  </label>
                  <input
                    type="file"
                    name="video"
                    accept="video/*"
                    ref={videoRef}
                    onChange={handleVideoInputChange}
                    required
                  />
                  {selectedVideoName ? (
                    <span>{selectedVideoName}</span>
                  ) : (
                    <span>No video file selected</span>
                  )}
                </div>
                <TextArea
                  placeholder="Description"
                  name="description"
                  onChange={dataChangeHandler}
                />
                <div>
                  <input
                    disabled={isLoading}
                    type="submit"
                    value={isLoading ? "ADDING EPISODE ..." : "ADD EPISODE"}
                    className="w-full bg-sky-blue rounded-3xl font-bold text-sm text-black mb-4 outline-none border-none cursor-pointer py-4"
                  />
                </div>
              </form>
            </Modal>
          )}
        </div>
        {isFetching ? (
          <Loader />
        ) : (
          <div>
            {episodes?.data?.data.map((item, index) => (
              <div key={item.id} className="flex items-center gap-6 mb-3">
                <p>{index + 1}</p>
                <div className="flex gap-2.5">
                  <div className="w-[200px] h-[100px]">
                    <img
                      src={item.cover}
                      alt={item.title}
                      className="w-full h-full rounded-lg object-cover"
                    />
                  </div>
                  <div>
                    <h1 className="text-lg font-semibold">{item.title}</h1>
                  </div>
                </div>
                <button
                  className="text-red text-sm font-bold ml-auto px-4 py-2"
                  onClick={() => removeEpisode(item.id)}
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
        )}
        <button
          className="font-bold mt-4 text-sky-blue"
          onClick={() => setShowModal(true)}
        >
          + Add Episode
        </button>
      </div>
    </Fragment>
  );
};

export default AddToPlaylist;
