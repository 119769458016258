import React, { Fragment } from "react";
import classes from "./Dashboard.module.css";
import Header from "../components/Header/header.component";
import Overview from "../components/Overview/overview.component";
import MiddleSect from "../components/MiddleSect/middlesect.component";
import { useOutletContext } from "react-router-dom";

const Dashboard = () => {
  const [setShowNav] = useOutletContext();
  return (
    <Fragment>
      <Header page="Dashboard" setShowNav={setShowNav}/>
      <Overview />
      <MiddleSect />
    </Fragment>
  );
};

export default Dashboard;
