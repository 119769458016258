import React, { useState, useEffect } from "react";
import classes from "./Events.module.css";
import EventCard from "../../components/UI/EventCard/EventCard";
import CreateEvent from "./CreateEvent";
import ButtonHeader from "../../components/UI/buttonHeader/buttonheader";
import Loader from "../../components/Loader";
import { useGetEvents, useGetFeaturedEvent } from "../../hooks/events.hook";
import EditEvent from "./EditEvent";
import SetFeatured from "./SetFeatured";

const EventMain = () => {
  const [creating, setCreating] = useState(false);
  const [editing, setEditing] = useState(false);
  const [pageData, setPageData] = useState({});
  const [settingFeatured, setSettingFeatured] = useState(false);
  const [editData, setEditData] = useState({})

  const showCreateHandler = () => {
    setCreating(true);
  };
  const showEditHandler = (data) => {
    setEditing(true);
    setEditData(data);
  };
  const hideCreateHandler = () => {
    setCreating(false);
  };
  const hideEditHandler = () => {
    setEditing(false);
    setEditData({})
  };
  const hideSettingHandler = () => {
    setSettingFeatured(false);
  };
  const { data, isFetching, refetch: reget } = useGetEvents();
  const {
    data: featured,
    isFetching: isGetting,
    refetch,
  } = useGetFeaturedEvent();
  
  useEffect(() => {
    if (data && data?.data?.data !== pageData) {
      setPageData(data?.data?.data);
    }
  }, [data]);
  return (
    <div className={classes.main}>
      {creating && <CreateEvent onClose={hideCreateHandler} />}
      {editing && <EditEvent onClose={hideEditHandler} data={editData} refetch={reget}/>}
      {settingFeatured && <SetFeatured data={pageData.upcoming_events} onClose={hideSettingHandler} refetch={refetch}/>}
      <div className={classes.featured}>
        <h3>Featured Event</h3>
        {isGetting ? (
          <Loader />
        ) : (
          <>
            {featured?.data?.data.map((item, index) => (
              <EventCard key={index} handler={showEditHandler} data={item} refetch={reget}/>
            ))}
            <h1
              className="font-medium text-sky-blue mt-2 cursor-pointer"
              onClick={() => setSettingFeatured(true)}
            >
              Add/Replace Featured Event
            </h1>
          </>
        )}
      </div>
      <div className={classes.upcoming}>
        <ButtonHeader
          header="Events"
          btn="CREATE NEW EVENT"
          handler={showCreateHandler}
        />
        <div className={classes.videos}>
          {isFetching ? (
            <Loader />
          ) : (
            <div className="flex flex-col gap-3">
              {pageData?.today_events?.length != 0 && (
                <div>
                  <h1 className="text-sky-blue font-bold text-xl mb-3">
                    Today
                  </h1>
                  <div className="flex gap-2 flex-wrap">
                    {pageData.today_events?.map((item) => (
                      <EventCard key={item.id} handler={showEditHandler} data={item} refetch={reget}/>
                    ))}
                  </div>
                </div>
              )}
              <div>
                <h1 className="text-sky-blue font-bold text-xl mb-3">
                  Upcoming Events
                </h1>
                <div className="flex gap-2 flex-wrap">
                  {pageData.upcoming_events?.map((item) => (
                    <EventCard key={item.id}  handler={showEditHandler} data={item} refetch={reget}/>
                  ))}
                </div>
              </div>
              <div>
                <h1 className="text-sky-blue font-bold text-xl mb-3">
                  Past Events
                </h1>
                <div className="flex gap-2 flex-wrap">
                  {pageData.past_events?.map((item) => (
                    <EventCard key={item.id} data={item}/>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventMain;
