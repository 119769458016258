import classes from "./styles.module.css";

export const VideoCard = ({ coverImage, title, speaker, action }) => {
  return (
    <div className={classes.card} onClick={action}>
      <div className={classes.img}>
        <img src={coverImage} alt={title}/>
      </div>
      <h3 className="line-clamp-2">{title}</h3>
      <h4>{speaker?.first_name} {speaker?.last_name}</h4>
    </div>
  );
};
