import classes from "./nav-item.module.css";
import { NavLink } from "react-router-dom";

const NavItem = ({ img, title, link, setShowNav, onClick }) => {
  return (
    <NavLink
      to={link}
      className={({ isActive, isPending }) =>
        isActive ? `${classes.link} ${classes.active}` : `${classes.link}`
      }
      onClick={() => {
        setShowNav && setShowNav(false);
        onClick && onClick();
      }}
    >
      <div className={classes.cont}>
        <img className={classes.svg} src={img} alt={title} />
        <h3>{title}</h3>
      </div>
    </NavLink>
  );
};

export default NavItem;
