import { UserAxiosInstance, AuthUserAxiosInstance } from "./instance";

export const Api = {
  auth: {
    signInEmail: async (data) => UserAxiosInstance.post("/login", data),
    refreshToken: async (data) => UserAxiosInstance.post("/refresh", data),
  },
  users: {
    getUsers: async (pageNumber) =>
      AuthUserAxiosInstance.get(`/users?page=${pageNumber}`),
    getSpeakers: async (pageNumber) =>
      AuthUserAxiosInstance.get(`/speakers?page=${pageNumber}`),
    getPending: async (pageNumber) =>
      AuthUserAxiosInstance.get(
        `/speakersApplications/pending?page=${pageNumber}`
      ),
    getRejected: async (pageNumber) =>
      AuthUserAxiosInstance.get(
        `/speakersApplications/rejected?page=${pageNumber}`
      ),
    getAllSpeakers: async () => AuthUserAxiosInstance.get("/allspeakers"),
  },
  videos: {
    getAllVideos: async (pageNumber) =>
      AuthUserAxiosInstance.get(`/getLatestVideos?page=${pageNumber}`),
    uploadVideo: async (data) =>
      AuthUserAxiosInstance.post("/create-masterclass", data),
    setFeaturedVideo: async (data) =>
      AuthUserAxiosInstance.post("/setFeaturedVideo", data),
    useGetFeaturedVideo: async () =>
      AuthUserAxiosInstance.get("/getFeaturedVideos"),
    editVideo: async (data) =>
      AuthUserAxiosInstance.put("/edit-masterclass", data),
    deleteVideo: async (data) =>
      AuthUserAxiosInstance.delete("/delete-masterclass", { data }),
  },
  events: {
    getAllEvents: async () => AuthUserAxiosInstance.get("/events"),
    createEvent: async (data) =>
      AuthUserAxiosInstance.post("/create-event", data),
    setFeaturedEvent: async (data) =>
      AuthUserAxiosInstance.post("/setFeaturedEvent", data),
    useGetFeaturedEvent: async () =>
      AuthUserAxiosInstance.get("/getFeaturedEvents"),
    editEvent: async (data) => AuthUserAxiosInstance.put("/edit-event", data),
    deleteEvent: async (data) =>
      AuthUserAxiosInstance.delete("/delete-event", { data }),
  },
  playlist: {
    getAllPlaylists: async () => AuthUserAxiosInstance.get("/playlist"),
    createPlaylist: async (data) =>
      AuthUserAxiosInstance.post("/playlist", data),
    addEpisode: async (data) => AuthUserAxiosInstance.post("/episode", data),
    getEpisodes: async (id) => AuthUserAxiosInstance.get("/episode?playlist="+id),
    deleteEpisode: async (id) => AuthUserAxiosInstance.delete("/episode?episode="+id),
  },
  competition: {
    getSubmissions: async () => AuthUserAxiosInstance.get("/competition"),
  }
};
