import React, { Fragment } from "react";
import Header from "../../components/Header/header.component";
import { useOutletContext } from "react-router-dom";
import { useGetCompetition } from "../../hooks/competition.hook";
import Loader from "../../components/Loader";

const Competition = () => {
  const [setShowNav] = useOutletContext();
  const { data, isFetching } = useGetCompetition();
  return (
    <Fragment>
      <Header page="Competition" setShowNav={setShowNav} />
      {isFetching ? (
        <Loader />
      ) : (
        <div className="mx-3.5 lg:mr-2.5 lg:ml-0 mt-5 overflow-auto">
          <h1>All Submissions</h1>
          <div className="flex flex-col">
            {data?.data?.data?.map((item) => (
              <div className="flex flex-row justify-between items-center p-4 border-b border-gray-200 text-sm md:text-base">
                <p>
                  {item.first_name} {item.last_name}
                </p>
                <p>{item.email}</p>
                <a href={item.video} download>
                  <button>Download</button>
                </a>
              </div>
            ))}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Competition;
