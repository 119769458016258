import classes from "./nav.module.css";
import NavItem from "./nav-item/nav-item.component";
import Dashboard from "../../assets/icon/dashboard.svg";
import Users from "../../assets/icon/users.svg";
import Speakers from "../../assets/icon/speakers.svg";
import Videos from "../../assets/icon/videos.svg";
import Events from "../../assets/icon/events.svg";
import Subscription from "../../assets/icon/subscription.svg";
import Settings from "../../assets/icon/settings.svg";
import Lgout from "../../assets/icon/logout.svg";
import { Fragment, useEffect } from "react";
import Cancel from "../../assets/icon/cancel.svg";
import Playlists from "../../assets/icon/playlists.svg";

const navitems = [
  {
    key: "I1",
    img: Dashboard,
    title: "Dashboard",
    link: "/",
  },
  {
    key: "I2",
    img: Videos,
    title: "Masterclass",
    link: "/videos",
  },
  {
    key: "I3",
    img: Playlists,
    title: "Playlists",
    link: "/playlists",
  },
  {
    key: "I4",
    img: Events,
    title: "Events",
    link: "/events",
  },
  {
    key: "I5",
    img: Users,
    title: "Users",
    link: "/users",
  },
  {
    key: "I6",
    img: Speakers,
    title: "Speakers",
    link: "/speakers",
  }, 
  {
    key: "I7",
    img: Subscription,
    title: "Subscription",
    link: "/subscription",
  },
  {
    key: "I8",
    img: Users,
    title: "Competition",
    link: "/competition",
  },
];

const navElement = (
  <Fragment>
    {navitems.map((item) => (
      <NavItem {...item} />
    ))}
  </Fragment>
);

const Nav = () => {
  const logout = () => {
    localStorage.removeItem("recoil-persist");
    window.location.reload();
  };
  return (
    <div className={classes.nav}>
      <h1>URGE</h1>
      {navElement}
      <div className={classes.bottom}>
        <NavItem img={Settings} link="/settings" title="Settings" />
        <NavItem img={Lgout} title="Logout" link="/l" onClick={logout} />
      </div>
    </div>
  );
};

export default Nav;

export const MobileNav = ({ showNav, setShowNav }) => {
  const logout = () => {
    localStorage.removeItem("recoil-persist");
    window.location.reload();
  };
  return (
    <div className={classes.mobileNav}>
      <div className={classes.t}>
        <h1>URGE</h1>
        <img
          src={Cancel}
          alt="close"
          onClick={() => {
            setShowNav(false);
          }}
        />
      </div>

      <div className={classes.j}>
        <div>
          {navitems.map((item) => (
            <NavItem {...item} setShowNav={setShowNav} />
          ))}
        </div>

        <div className={classes.bottom}>
          <NavItem img={Settings} title="Settings" link="/settings" setShowNav={setShowNav} />
          <NavItem img={Lgout} title="Logout" link="/l" setShowNav={setShowNav} />
        </div>
      </div>
    </div>
  );
};
