import React, { Fragment } from "react";
import classes from "./Events.module.css";
import Header from ".././../components/Header/header.component";
import { useOutletContext, Outlet } from "react-router-dom";

const Events = () => {
  const [setShowNav] = useOutletContext();
  return (
    <Fragment>
      <Header page="Events" setShowNav={setShowNav}/>

      <Outlet />
    </Fragment>
  );
};

export default Events;
