import React, { useState } from "react";
import classes from "./Applications.module.css";
import { NavLink, Outlet } from "react-router-dom";
import Pagination from "../../components/UI/Pagination/pagination";

const Applications = () => {
  const [pgData, setPgData] = useState({});

  return (
    <div className={classes.cont}>
      <div className={classes.t}>
        <div className={classes.nav}>
          <NavLink
            to="/speakers/applications"
            end
            className={({ isActive }) =>
              isActive ? `${classes.link} ${classes.active}` : `${classes.link}`
            }
          >
            Pending
          </NavLink>

          <NavLink
            to="/speakers/applications/rejected"
            end
            className={({ isActive }) =>
              isActive ? `${classes.link} ${classes.active}` : `${classes.link}`
            }
          >
            Rejected
          </NavLink>
        </div>
        <div className={classes.pagination}>
          <Pagination
            currentPage={pgData.currentPage}
            totalPages={pgData.totalPages}
            onPageChange={pgData.handlePageChange}
          />
        </div>
      </div>

      <Outlet context={[setPgData]} />
    </div>
  );
};

export default Applications;
