import { useMutation } from "react-query";
import { toast } from "react-hot-toast";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Api } from "../api/Api";
import userAtom from "../atoms/user.atom";
import errorAtom from "../atoms/error.atom";
import { EMAIL_NOT_VERIFIED_ERROR } from "../utils/constants";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Auth } from "../utils/storage";
import { handleError } from "../utils/utils";

export const useLogin = () => {
  const setUserData = useSetRecoilState(userAtom);
  const setErrorMessage = useSetRecoilState(errorAtom);
  return useMutation((data) => Api.auth.signInEmail(data), {
    onSuccess: ({ data }) => {
      toast.success(data.message);
      setUserData(data.data);
      // navigate to login
    },
    onError: (err) => {
      toast.error(err);
      // handleToastError(err);
    },
  });
};

export const useCheckLoggedInHook = (currentRoute) => {
  const navigate = useNavigate();

  const userData = useRecoilValue(userAtom);
  useEffect(() => {
    const token = Auth.getToken();
    navigate(currentRoute);
  }, []);
};

export const useRefreshToken = () => {
  const setUserData = useSetRecoilState(userAtom);
  const setErrorMessage = useSetRecoilState(errorAtom);
  return useMutation((data) => Api.auth.refreshToken(data), {
    onSuccess: ({ data }) => {
      toast.success(data.message);
      setUserData(data.data);
    },
    onError: (err) => {
      handleError(err)
    },
  });
};