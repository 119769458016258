import classes from "./card.module.css";

const Card = (props) => {
  return (
    <div className={classes.cont}>
      <div className={classes.img} style={{ backgroundColor: props.color }}>
        <img src={props.img} alt={props.title} />
      </div>

      <div className={classes.side}>
        <h4>{props.title}</h4>
        <p>{props.detail}</p>
      </div>
    </div>
  );
};

export default Card;
