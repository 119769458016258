import React, { useContext, useState, useEffect } from "react";
import classes from "./Applications.module.css";
import Table from "../../components/Table/table";
import { useOutletContext } from "react-router-dom";
import Loader from "../../components/Loader";
import { useGetRejected } from "../../hooks/users.hook";

const RejectedApplications = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [setPgData] = useOutletContext();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const { data, isFetching } = useGetRejected(currentPage);

  useEffect(() => {
    setPgData({
      currentPage: currentPage,
      totalPages: data?.data?.pages,
      handlePageChange: handlePageChange,
    });
  }, [currentPage]);

  return (
    <div className={classes.body}>
      {isFetching ? <Loader /> : <Table usersData={data?.data?.data} />}
    </div>
  );
};

export default RejectedApplications;
