import React from "react";
import { TailSpin } from "react-loader-spinner";
import classes from "./index.module.css";

const Loader = () => {
  return (
    <div className={`${classes.g} ${classes.l}`}>
      <TailSpin width={40} height={40} color="#00B4D7" />
    </div>
  );
};

export default Loader;

export const Spinner = () => {
  return (
    <div className={classes.g}>
      <TailSpin width={30} height={30} color="#00B4D7" />
    </div>
  );
};

export const PageLoader = ({ loader }) => {
  if (!loader) {
    return null;
  }
  return (
    <div className={`${classes.g} ${classes.pl}`}>
      <TailSpin width={40} height={40} color="#00B4D7" />
    </div>
  );
};
