import React from "react";
import classes from "./table.module.css";
import { Fragment } from "react";

const Table = ({ usersData }) => {
  console.log(usersData)
  return (
    <Fragment>
      <table className={classes.table}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email Address</th>
            {usersData?.some((user) => user.userType) && <th>User Type</th>}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {usersData?.map((user, index) => (
            <tr key={index}>
              <td>
                {user.first_name || user.user.first_name} {user.last_name || user.user.last_name}
              </td>
              <td>{user.email || user.user.email}</td>
              {user.userType && <td>{user.userType}</td>}
              <td>
                <button>Edit</button>
                <button>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  );
};

export default Table;
