import classes from "./featured-event.module.css";
import React, { Fragment } from "react";

const FeaturedEvent = () => {
  return (
    <Fragment>
      <h3>Featured Event</h3>
      <div></div>
    </Fragment>
  );
};

export default FeaturedEvent;
