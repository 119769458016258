import React, { useState } from "react";
import classes from "./page-layout.module.css";
import Nav, {MobileNav} from "../nav/nav.component";
import { Outlet } from "react-router-dom";

const PageLayout = (props) => {
  const [showNav, setShowNav] = useState(false);
  return (
    <div className={classes.dash}>
      <Nav />
      {showNav && <MobileNav showNav={showNav} setShowNav={setShowNav}/>}
      <div className={classes.f}>
        <Outlet context={[setShowNav]}/>
      </div>
    </div>
  );
};

export default PageLayout;
