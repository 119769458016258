import classes from "./header.module.css";
import Search from "../../assets/icon/search.svg";
import Menu from "../../assets/icon/menu.svg";
import Back from "../../assets/icon/back";
import { useNavigate } from "react-router-dom";

const Header = ({ page, setShowNav, back }) => {
  const navigate = useNavigate();
  const showNav = () => {
    setShowNav(true);
  };
  return (
    <div className={classes.cont}>
      <div className={classes.hb}>
        {back ? (
          <div className={classes.back} onClick={() => navigate(-1)}>
            <Back />
          </div>
        ): <img className={classes.menu} src={Menu} alt="Menu" onClick={showNav} />}
        <h1 className={classes.headingOne}>{page}</h1>
      </div>

      <div className={classes.right}>
        <div className={classes.searchCont}>
          <input type="text" placeholder="Search" />
          <div className={classes.search}>
            <img src={Search} alt="Search Btn" />
          </div>
        </div>

        <div className={classes.profile}>
          {/* <img src={Search} alt="Search Btn" /> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
