import classes from "./styles.module.css";

const ButtonHeader = ({header, btn, handler}) => {
  return (
    <div className={classes.header}>
      <h3>{header}</h3>
      <button onClick={handler}>{btn}</button>
    </div>
  );
};

export default ButtonHeader;
