import React, { Fragment, useState, useRef } from "react";
import { useNavigate, Link, useOutletContext, Outlet } from "react-router-dom";
import Upload from "../../assets/icon/upload.svg";
import classes from "./Videos.module.css";
import Header from ".././../components/Header/header.component";
import ButtonHeader from "../../components/UI/buttonHeader/buttonheader";
import { useGetVideos, useGetFeaturedVideo } from "../../hooks/videos.hook";
import Loader from "../../components/Loader";
import { VideoCard } from "../../components/UI/Card";
import SetFeatured from "./SetFeatured";

const Videos = () => {
  const navigate = useNavigate();
  const [setShowNav] = useOutletContext();
  const [settingFeatured, setSettingFeatured] = useState(false);
  // const [file, setFile] = useState(null);
  // const inputRef = useRef();

  const [currentPage, setCurrentPage] = useState(1);

  const { data, isFetching } = useGetVideos(currentPage);
  const { data: featured, isFetching: isGetting, refetch } = useGetFeaturedVideo();

  // const handleDragOver = (event) => {
  //   event.preventDefault();
  // };
  // const handleDrop = (event) => {
  //   event.preventDefault();
  //   setFile(event.dataTransfer.files);
  // };
  const showCreateHandler = () => {
    navigate("create");
  };
  // const handlePageChange = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  // };
  // const handleVideoClick = (videoId) => {
  //   navigate(`/video-detail/${videoId}`);
  // };
  const hideSettingHandler = () => {
    setSettingFeatured(false);
  };

  return (
    <Fragment>
      <Header page="Videos" setShowNav={setShowNav} />
      <div className="flex-1 overflow-auto">
        {settingFeatured && <SetFeatured data={data?.data?.data} onClose={hideSettingHandler} refetch={refetch}/>}
        <div className={classes.t1}>
          {/* <div className={classes.v}>
            <h3>Video for the day</h3>
            {!file ? (
              <div
                className={classes.dropzone}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                <img src={Upload} alt="upload" />
                <p>Drag files here to upload</p>
                <p>OR</p>
                <input
                  type="file"
                  name=""
                  id=""
                  onChange={(event) => setFile(event.target.files)}
                  hidden
                  ref={inputRef}
                />

                <button onClick={() => inputRef.current.click()}>Browse</button>
              </div>
            ) : (
              <div>
                <ul>
                  {Array.from(file).map((file, idx) => (
                    <li key={idx}>{file.name}</li>
                  ))}
                </ul>
                <button onClick={() => setFile(null)}>Cancel</button>
              </div>
            )}
          </div> */}
          <div className={classes.w}>
            <h3>Featured Video</h3>
            {isGetting ? (
              <Loader />
            ) : (
              <>
                {featured?.data?.data.map((item) => (
                  <Link
                    key={item.id}
                    to={`/videos/detail/${item.id}`}
                    state={{ videoData: item }}
                  >
                    <div className="">
                      <div className="flex h-full">
                        <img
                          className="object-cover rounded-lg max-h-[500px]"
                          src={item.coverImage}
                          alt={item.title}
                        />
                      </div>
                      <h3 className="font-semibold mt-1.5 line-clamp-2">
                        {item.title}
                      </h3>
                      <h4 className="text-xs font-semibold mt-0.5">
                        {item.speaker?.first_name} {item.speaker?.last_name}
                      </h4>
                    </div>
                  </Link>
                ))}
                <h1 className="font-medium text-sky-blue mt-2 cursor-pointer" onClick={()=> setSettingFeatured(true)}>Add/Replace Featured Video</h1>
              </>
            )}
          </div>
        </div>
        <div className={classes.t2}>
          <ButtonHeader
            header="All Videos"
            btn="ADD NEW VIDEO"
            handler={showCreateHandler}
          />
          <div className={classes.videos}>
            {isFetching ? (
              <Loader />
            ) : (
              data?.data?.data?.map((item) => (
                <Link
                  key={item.id}
                  to={`/videos/detail/${item.id}`}
                  state={{ videoData: item }}
                >
                  <VideoCard {...item} />
                </Link>
              ))
            )}
          </div>
        </div>
      </div>

      <Outlet />
    </Fragment>
  );
};

export default Videos;
