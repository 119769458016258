import React, { Fragment, useState, useRef } from "react";
import Header from ".././../components/Header/header.component";
import classes from "./styles.module.css";
import { TagInput } from "../../components/input";
import { useCreateVideo } from "../../hooks/videos.hook";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Loader from "../../components/Loader";
import { SpeakerInput } from "../../components/input";
import { useGetAllSpeakers } from "../../hooks/users.hook";

const CreateVideo = () => {
  const navigate = useNavigate();
  const [videoData, setvideoData] = useState({
    title: "",
    speaker: "",
    description: "",
    type: "free",
    tags: null,
  });
  
  const videoRef = useRef(null);
  const coverImageRef = useRef(null);
  const [selectedVideoName, setSelectedVideoName] = useState("");
  const [selectedCoverImageName, setSelectedCoverImageName] = useState("");
  const { mutate, isLoading } = useCreateVideo();
  const { data, isFetching } = useGetAllSpeakers();

  const videoDataChangeHandler = (e) => {
    const { name, value } = e.target;
    setvideoData({ ...videoData, [name]: value });
  };
  const speakerDataChangeHandler = (id) => {
    setvideoData({ ...videoData, speaker: id });
  };

  const handleVideoInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedVideoName(file.name);
    }
  };

  const handleCoverImageInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedCoverImageName(file.name);
    }
  };
  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("video", videoRef.current.files[0]);
    formData.append("coverImage", coverImageRef.current.files[0]);
    formData.append("title", videoData.title);
    formData.append("speaker", videoData.speaker);
    formData.append("description", videoData.description);
    formData.append("type", videoData.type);
    formData.append("tags", videoData.tags);
    mutate(formData, {
      onSuccess: () => {
        navigate("/videos");
      },
    });
  };

  return (
    <Fragment>
      <Header page="Add New Video" back />
      <div className={classes.b}>
        <form className={classes.form} onSubmit={submitHandler}>
          <div className={classes.fileUp}>
            <label htmlFor="video" onClick={() => videoRef.current.click()}>
              Upload Video
            </label>
            <input
              type="file"
              name="video"
              accept="video/*"
              ref={videoRef}
              onChange={handleVideoInputChange}
              required
            />
            {selectedVideoName ? <span>{selectedVideoName}</span>: <span>No video file selected</span>}
          </div>
          <div className={classes.fileUp}>
            <label
              htmlFor="coverImage"
              onClick={() => coverImageRef.current.click()}
            >
              Upload Cover Image
            </label>
            <input
              name="coverImage"
              type="file"
              accept="image/*"
              ref={coverImageRef}
              onChange={handleCoverImageInputChange}
              required
            />
            {selectedCoverImageName ? <span>{selectedCoverImageName}</span> : <span>No cover image selected</span>}
          </div>
          <div className={classes.formGroup}>
            <input
              type="text"
              placeholder="Title"
              name="title"
              onChange={videoDataChangeHandler}
              value={videoData.title}
              required
            />
          </div>
          {isFetching ? (
            <Loader />
          ) : (
            <SpeakerInput
              data={data?.data?.data}
              speakerDataChangeHandler={speakerDataChangeHandler}
            />
          )}
          <div className={classes.formGroup}>
            <textarea
              name="description"
              placeholder="Description"
              onChange={videoDataChangeHandler}
              value={videoData.description}
              required
            ></textarea>
          </div>
          <div className={`${classes.formGroup} ${classes.w50}`}>
            <label htmlFor="type">Type</label>
            <select
              name="type"
              id="type"
              onChange={videoDataChangeHandler}
              value={videoData.type}
              required
            >
              <option value="free">Free</option>
              <option value="members">Insiders Only</option>
            </select>
          </div>
          <TagInput setData={setvideoData} data={videoData} w />
          <div className={classes.submit}>
            {isLoading ? <Loader /> : <input type="submit" value="ADD VIDEO" />}
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default CreateVideo;
