import React, { Fragment, useRef, useState } from "react";
import Header from "../../components/Header/header.component";
import {
  InputField,
  SpeakerInput,
  TextArea,
  ImageSelector,
} from "../../components/input";
import { useGetAllSpeakers } from "../../hooks/users.hook";
import Loader from "../../components/Loader";
import classes from "../CreateVideo/styles.module.css";
import { useCreatePlaylist } from "../../hooks/playlist.hook";
import { useNavigate } from "react-router-dom";

const CreatePlaylist = () => {
  const navigate = useNavigate();
  const [playListData, setPlayListData] = useState({
    title: "",
    speaker: "",
    description: "",
  });
  const [fileName, setFileName] = useState();
  const { data, isFetching } = useGetAllSpeakers();
  const { mutate, isLoading } = useCreatePlaylist();
  const speakerDataChangeHandler = (id) => {
    setPlayListData({ ...playListData, speaker: id });
  };
  const fileRef = useRef(null);
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
    }
  };
  const playlistDataChangeHandler = (e) => {
    const { name, value } = e.target;
    setPlayListData({ ...playListData, [name]: value });
  };
  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", playListData.title);
    formData.append("speaker", playListData.speaker);
    formData.append("description", playListData.description);
    formData.append("cover", fileRef.current.files[0]);
    mutate(formData, {
      onSuccess: () => {
        navigate("/playlists");
      },
    });
  };
  return (
    <Fragment>
      <Header page="Create Playlist" back />
      <div className={classes.b}>
        <h3 className="font-bold text-sm text-sky-blue mt-2.5 mb-7">
          Playlist Information
        </h3>
        <form onSubmit={submitHandler}>
          <InputField
            placeholder="Title"
            name="title"
            onChange={playlistDataChangeHandler}
          />
          {isFetching ? (
            <Loader />
          ) : (
            <SpeakerInput
              data={data?.data?.data}
              speakerDataChangeHandler={speakerDataChangeHandler}
              name="speaker"
            />
          )}
          <TextArea
            placeholder="Description"
            name="description"
            onChange={playlistDataChangeHandler}
          />
          <ImageSelector
            label="Cover Image"
            name="cover"
            ref={fileRef}
            onChange={handleFileInputChange}
            fileName={fileName}
          />
          <input
            className="py-4 px-14 font-semibold text-sm text-black bg-sky-blue rounded-full float-right mt-10"
            type="submit"
            value="Next"
            disabled={isLoading}
          />
        </form>
      </div>
    </Fragment>
  );
};

export default CreatePlaylist;
