import React from "react";
import Modal from "../Modal/Modal";
import { LoaderIcon } from "react-hot-toast";

const Dialog = ({onClose, actionMsg, action, status}) => {

    return(
        <Modal onClose={onClose}>
            <div className="p-3 flex flex-col items-center gap-4 max-w-[500px]">
                <h3 className="font-bold text-lg text-center">{actionMsg}</h3>
                <div className="flex gap-3">
                    <button className="bg-red-500 px-5 py-3 rounded-full" onClick={action}> {status? <LoaderIcon/> :"Yes"}</button>
                    <button className="bg-blue-500 px-5 py-3 rounded-full" onClick={onClose}>No</button>
                </div>
            </div>
        </Modal>
    );
};

export default Dialog;
